@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Amatic SC";
}
html,
body {
  overflow-x: hidden;
}

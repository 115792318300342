.home-background {
  background-image: url("../assets/foods/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.about-background {
  background-image: url("../assets/foods/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bg-image-nav-links {
  background-image: url("../assets/foods/bg-empanada.png");
  background-size: cover;
  background-position: center;
}

.my-drop {
  filter: drop-shadow(30px 10px 4px rgba(0, 0, 0, 0.4));
}

.bg-img-card-alfajor {
  background-image: url("../assets/foods/alfajor.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.bg-img-card-chipa {
  background-image: url("../assets/foods/chipa.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.bg-img-card-pastafrola {
  background-image: url("../assets/foods/pastafrola.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.bg-img-card-empanada {
  background-image: url("../assets/foods/empanadas.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.hover-effect {
  transition: 0.5s;
}

.hover-effect:hover {
  background: rgb(0, 0, 0, 0.7);
  transition: 1s;
}

.shadow-text {
  color: #000;
  text-shadow: 0 0 0.5px #fff, 0 0 0.8px #fff, 0 0 1.1px #fff,
    0 0 1.2px rgb(255, 255, 255), 0 0 2px rgb(255, 255, 255),
    0 0 2.5px rgb(255, 255, 255), 0 0 3px rgb(255, 255, 255),
    0 0 4px rgb(255, 255, 255);
}
